import {Component} from '@angular/core'
import {FooterComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import version from '../assets/package.json'
import {HeaderComponent} from './common/header/header.component'
import {ConfigService} from './services/config.service'
import {RouterOutlet} from '@angular/router'
import {AsyncPipe} from '@angular/common'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, AsyncPipe, HeaderComponent, FooterComponent]
})
export class AppComponent {
  public version = version.version
  public copyrightYears = `2022-${new Date().getFullYear()}`

  constructor(public configService: ConfigService) {
  }
}
