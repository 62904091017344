import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {HOME_ROUTE_PATH, LOGIN_ROUTE_PATH} from './application/data-types'
import {InterestResolver} from './application/interest.resolver'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: HOME_ROUTE_PATH,
    loadComponent: () => import('./home/home/home.component').then(m => m.HomeComponent),
    canActivate: [authGuard],
    resolve: {
      interests: InterestResolver
    }
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadComponent: () => import('./login/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]
