import {inject, Injectable} from '@angular/core'
import {forkJoin, Observable} from 'rxjs'
import {InterestService} from '../services/interest.service'

@Injectable({providedIn: 'root'})
export class InterestResolver {
  private service = inject(InterestService)

  resolve(): Observable<any> {
    return forkJoin([this.service.getAllInterests(), this.service.getAllInterestTypes()])
  }
}
